import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';

import { TextField, FormControl, InputAdornment } from '@material-ui/core';
import { textField, fieldFontSize } from './formFieldStyles';
import { formatDate } from '../../config/formatters';

const styles = (theme) => ({
  text1: textField(theme),
  textFont: fieldFontSize(theme),
  inputTextHighlight: {
    color: lightBlue[300],
    ...fieldFontSize(theme),
  },
});

const ReadOnlyField = (props) => {
  const { classes, displayName, value } = props;

  let displayValue = value;
  let textStyle = {};
  if (
    displayName.toUpperCase().includes('MIN') ||
    displayName.toUpperCase().includes('MAX') ||
    displayName.toUpperCase().includes('DATE')
  ) {
    if (value !== null && value !== undefined) {
      if (displayName.toUpperCase().includes('HARVEST')) {
        textStyle = { color: teal[300] };
        displayValue = value ? formatDate(value) : '';
      } else if (displayName.toUpperCase().includes('DELIVERY')) {
        textStyle = { color: lightBlue[300] };
        displayValue = value ? formatDate(value) : '';
      } else if (displayName.toUpperCase() === 'MAX. FREEZE') {
        displayValue = value;
      } else if (displayName.toUpperCase().includes('FREEZE')) {
        displayValue = value ? formatDate(value) : '';
      } else {
        displayValue = value ? formatDate(value) : '';
      }
    } else {
      displayValue = '';
    }
  } else if (displayName.toUpperCase().includes('DIRECTION')) {
    if (value === 'BUY') {
      textStyle = { backgroundColor: lightBlue[500] };
    } else if (value === 'SELL') {
      textStyle = { backgroundColor: teal[500] };
    }
  } else if (displayName.toUpperCase().includes('START DAY')) {
    displayValue = formatDate(value);
  }
  return (
    <FormControl className={props.noMargin ? null : classes.text1} fullWidth={props.fullWidth}>
      <TextField
        id={props.key}
        key={props.key}
        type="text"
        label={displayName}
        variant="filled"
        readOnly
        multiline
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        value={displayValue}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          readOnly: true,
          className: props.highlight ? classes.inputTextHighlight : classes.textFont,
          style: textStyle,
          endAdornment: (
            <InputAdornment position="end">{props.adornment ? props.adornment : ''}</InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

ReadOnlyField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReadOnlyField);
