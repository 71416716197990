import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import debounceRender from 'react-debounce-render';

import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, Icon } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

import Tab from './Tab';
import GridHeader from './GridHeader';
import GridRow from './GridRow';
import TotalsRow from './TotalsRow';
import Details from './Details';

import TrafficLights from '../../../commons/components/trafficLights';
import CheckboxField from '../../../commons/components/formFields/checkboxField';
import { directions, status, avatarSize } from '../../../commons/models/constants';

import UserAvatar from '../../../commons/components/userAvatar';

import CollapseContainer from '../../../commons/components/collapseContainer';

import ordersModule from '../../orders';
import tradesModule from '../../trades';

import {
  getAll,
  getOrganisations,
  getActiveUser,
  getOrders,
  getOrganisationsObject,
  getUsersObject,
} from '../selectors';

import { UPDATE_RESPONSE_FORM, UPDATE_RFQS, RFQ_STATUS_ENDED } from '../constants';
import { updateForm, updateRFQs } from '../actions';

const styles = (theme) => ({
  activeRfqContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit * 2,
  },
  activeRfqContentBorder: {
    padding: theme.spacing.unit,
    overflow: 'auto',
    border: '1px solid',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  activeRfqContent: {
    overflowY: 'scroll',
    flex: 1,
  },
});

class Active extends React.PureComponent {
  configStatus = {
    [status.SUSPENDED]: {
      title: 'Orders Suspended',
      forbidden: {
        [status.FILLED]: true,
        [status.CANCELLED]: true,
        [status.SUSPENDED]: true,
      },
      color: amber[600],
    },
    [status.CANCELLED]: {
      title: 'Orders Cancelled',
      forbidden: {
        [status.FILLED]: true,
        [status.CANCELLED]: true,
      },
      color: red[500],
    },
    [status.FILLED]: {
      title: '',
      forbidden: {},
    },
    [status.ACTIVE]: {
      title: 'Orders Activated',
      forbidden: {
        [status.FILLED]: true,
        [status.CANCELLED]: true,
        [status.ACTIVE]: true,
      },
      color: teal[300],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      sender: '',
      showActive: false,
      detailsExpanded: false,
      tradesExpanded: true,
      interestsExpanded: true,
      responsesExpanded: true,
    };
    this.myRef = React.createRef();
  }

  getTrades = (activeRfq) => {
    let trades = [];

    if (activeRfq !== null) {
      trades = this.props.trades
        .filter((trade) => Object.prototype.hasOwnProperty.call(trade, 'rfqId'))
        .filter((trade1) => trade1.rfqId === activeRfq.id);
    }
    return trades;
  };

  getTradesByOrderGroup = (trades) => {
    const ids = [];
    const groups = [];

    trades.forEach((t) => {
      if (ids.indexOf(t.tradeGroupId) === -1) {
        ids.push(t.tradeGroupId);
      }
    });

    ids.forEach((id) => {
      const newGroup = trades.filter((trade) => trade.tradeGroupId === id);
      groups.push(newGroup);
    });
    return groups;
  };

  getResponses = (activeRfq) => {
    let responses = [];

    if (activeRfq !== null) {
      const { showActive } = this.state;

      responses = JSON.parse(JSON.stringify(this.props.orders))
        .filter((order) => Object.prototype.hasOwnProperty.call(order, 'rfqId'))
        .filter((order1) => order1.rfqId === activeRfq.id)
        .filter((order2) => order2.direction !== activeRfq.direction)
        .filter((order3) => {
          if (showActive) {
            return (
              order3.status !== status.SUSPENDED &&
              order3.status !== status.CANCELLED &&
              order3.status !== status.FILLED
            );
          }
          return true;
        });
      // if (activeRfq.direction === 'BUY') {
      //   responses.sort((a, b) => (a.price > b.price ? 1 : b.price > a.price ? -1 : 0));
      // } else {
      //   responses.sort((a, b) => (a.price < b.price ? 1 : b.price < a.price ? -1 : 0));
      // }
    }

    return responses;
  };

  getInterests = (activeRfq) => {
    let interests = [];

    if (activeRfq !== null) {
      const { showActive } = this.state;

      interests = JSON.parse(JSON.stringify(this.props.orders))
        .filter((order) => Object.prototype.hasOwnProperty.call(order, 'rfqId'))
        .filter((order1) => order1.rfqId === activeRfq.id)
        .filter((order2) => order2.direction === activeRfq.direction)
        .filter((order3) => {
          if (showActive) {
            return (
              order3.status !== status.SUSPENDED &&
              order3.status !== status.CANCELLED &&
              order3.status !== status.FILLED
            );
          }
          return true;
        });

      // if (activeRfq.direction === 'BUY') {
      //   interests.sort((a, b) => (a.price < b.price ? 1 : b.price < a.price ? -1 : 0));
      // } else {
      //   interests.sort((a, b) => (a.price > b.price ? 1 : b.price > a.price ? -1 : 0));
      // }
    }

    return interests;
  };

  getWeightClasses = (responses, interests) => {
    const weightClasses = [];

    responses.forEach((rsp) => {
      rsp.orderData.forEach((order) => {
        const wgt = order.contract.underlying.weightClass;
        if (weightClasses.indexOf(wgt) === -1) {
          weightClasses.push(wgt);
        }
      });
    });

    interests.forEach((rsp) => {
      rsp.orderData.forEach((order) => {
        const wgt = order.contract.underlying.weightClass;
        if (weightClasses.indexOf(wgt) === -1) {
          weightClasses.push(wgt);
        }
      });
    });

    weightClasses.sort();

    if (weightClasses.length === 0) {
      weightClasses.push('');
    }
    return weightClasses;
  };

  cancelAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.CANCELLED);
  };

  suspendAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.SUSPENDED);
  };

  activateAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.ACTIVE);
  };

  // updateAllStatus =(status)=>{

  // TODO: move to saga
  updateAllStatus = (newStatus) => {
    if (!this.configStatus[newStatus]) return;

    const newStatusConfig = this.configStatus[newStatus];

    const { user, orders, activeRfq = {} } = this.props;

    const filteredOrders = orders
      .filter((order) => Object.prototype.hasOwnProperty.call(order, 'rfqId'))
      .filter((order) => order.rfqId === activeRfq.id)
      .filter((order) => order.createdByUserId === user.id)
      .filter((order) => !newStatusConfig.forbidden[order.status]);

    const amendedOrders = [];

    filteredOrders.forEach((order) => {
      const orderId = order.id;
      const orderToAmend = {
        orderId,
        status: newStatus,
        orderData: order.orderData,
      };
      amendedOrders.push(orderToAmend);
      const payload = { items: [orderToAmend] };
      this.props.updateOrders(payload);
    });

    this.setState({
      snackbarOpen: true,
      snackbarContent: `${newStatusConfig.title}: ${amendedOrders.length}`,
      snackbarColor: newStatusConfig.color,
    });
  };

  addResponseForm = (orders) => {
    const { activeRfq } = this.props;
    if (activeRfq) {
      if (activeRfq.status === 'ACTIVE') {
        const payload = {
          type: 'response',
          action: 'openForm',
          rfq: activeRfq,
          orders,
        };

        this.props.updateResponseForm(payload);
      }
    }
  };

  addTradeForm = (orders) => {
    const { activeRfq } = this.props;

    if (activeRfq) {
      if (activeRfq.status === 'ACTIVE') {
        const payload = {
          type: 'execute',
          action: 'openForm',
          rfq: activeRfq,
          orders,
        };

        this.props.updateResponseForm(payload);
      }
    }
  };

  addAmendForm = (orders) => {
    const { activeRfq } = this.props;

    if (activeRfq) {
      if (activeRfq.status === 'ACTIVE') {
        const payload = {
          type: 'amend',
          action: 'openForm',
          rfq: activeRfq,
          orders,
        };
        console.log('payload', payload);
        this.props.updateResponseForm(payload);
      }
    }
  };

  endRFQ = () => {
    const { activeRfq, user } = this.props;

    if (activeRfq.status !== RFQ_STATUS_ENDED) {
      const payload = {
        userId: user.id,
        status: RFQ_STATUS_ENDED,
        rfqs: [activeRfq],
      };

      this.props.endRFQ(payload);
    }
  };

  handleChecked = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, activeRfq, user, contacts } = this.props;
    const interests = this.getInterests(activeRfq);
    const responses = this.getResponses(activeRfq);

    const weightClasses = this.getWeightClasses(responses, interests);

    const trades = this.getTrades(activeRfq);
    const tradesByGroup = this.getTradesByOrderGroup(trades);

    let responsesTitle = '';
    let interestsTitle = '';
    if (activeRfq !== null && activeRfq !== undefined) {
      responsesTitle =
        (activeRfq.organisationId === user.organisationId) === true
          ? activeRfq.direction === directions.BUY
            ? 'SELLERS'
            : 'BUYERS'
          : 'MY INTEREST';
      interestsTitle =
        (activeRfq.organisationId === user.organisationId) === true
          ? 'MY INTEREST'
          : activeRfq.direction === directions.BUY
          ? 'BUYERS'
          : 'SELLERS';
    }

    const status = activeRfq !== null ? activeRfq.status : RFQ_STATUS_ENDED;
    const isMyOrder = activeRfq !== null ? activeRfq.createdByUserId === user.id : false;

    return (
      <div ref={this.myRef} className={classes.activeRfqContainer}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h5" color="inherit">
              ACTIVE REQUEST
            </Typography>
            <div style={{ flexGrow: 1 }} />

            <div style={{ flexGrow: 1 }} />
            {activeRfq !== null && activeRfq !== undefined && (
              <TrafficLights
                cancelAll={this.cancelAll}
                suspendAll={this.suspendAll}
                activateAll={this.activateAll}
                hideOrderButton
              />
            )}
          </Toolbar>
        </AppBar>
        <div style={{ padding: '2px' }}></div>

        {activeRfq !== null && <Tab data={activeRfq} contacts={contacts} showDetails />}

        <div className={classes.activeRfqContent}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {status !== RFQ_STATUS_ENDED && (
              <div style={{ paddingLeft: '4px', paddingRight: '4px' }}>
                <Button variant="outlined" onClick={() => this.addResponseForm(null)}>
                  <Icon style={{ paddingRight: '8px' }}>add</Icon>Add New Response
                </Button>
              </div>
            )}
            {activeRfq !== null && (
              <div
                style={{
                  display: 'flex',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CheckboxField
                  accessor="showActive"
                  displayName="Show Only Active Responses?"
                  value={this.state.showActive}
                  handleChecked={this.handleChecked}
                  width="270px"
                />
              </div>
            )}
            {status !== RFQ_STATUS_ENDED && isMyOrder && (
              <div style={{ paddingLeft: '4px', paddingRight: '4px' }}>
                <Button variant="contained" color="secondary" onClick={this.endRFQ}>
                  <Icon style={{ paddingRight: '8px' }}>clear</Icon>End Rfq
                </Button>
              </div>
            )}
          </div>
          {activeRfq !== null && (
            <CollapseContainer
              onClick={() => this.setState({ detailsExpanded: !this.state.detailsExpanded })}
              expanded={this.state.detailsExpanded}
              title="DETAILS"
            >
              <Details activeRfq={activeRfq} />
            </CollapseContainer>
          )}
          {trades.length > 0 && (
            <CollapseContainer
              onClick={() => this.setState({ tradesExpanded: !this.state.tradesExpanded })}
              expanded={this.state.tradesExpanded}
              title="MY TRADES"
            >
              {tradesByGroup.map((t) => {
                const userData = {
                  userName: t[0].counterpartyUser,
                  organisationName: t[0].counterpartyCompanyName,
                  organisationShortName: t[0].counterpartyCompany,
                  organisationId: t[0].counterpartyOrganisationId,
                };

                return (
                  <div
                    key={t[0].id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      onClick={() =>
                        this.props.onTradeClick({ open: true, tradeIds: [t[0].tradeGroupId] })
                      }
                      style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <UserAvatar {...userData} size={avatarSize.SMALL} tooltip />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 10,
                        paddingRight: '4px',
                        paddingBottom: '4px',
                      }}
                    >
                      <TotalsRow rows={t} noColor />
                    </div>
                  </div>
                );
              })}
              <TotalsRow rows={trades} />
            </CollapseContainer>
          )}

          <CollapseContainer
            onClick={() =>
              this.setState({
                responsesExpanded: !this.state.responsesExpanded,
              })
            }
            expanded={this.state.responsesExpanded}
            title={responsesTitle}
          >
            <>
              <GridHeader weightClasses={weightClasses} />
              {responses.map((i) => {
                return (
                  <GridRow
                    key={i.id}
                    orderGroup={i}
                    weightClasses={weightClasses}
                    activeRfq={activeRfq}
                    interest={false}
                    addTradeForm={this.addTradeForm}
                    addResponseForm={this.addResponseForm}
                    addAmendForm={this.addAmendForm}
                  />
                );
              })}
            </>
          </CollapseContainer>

          <CollapseContainer
            onClick={() =>
              this.setState({
                interestsExpanded: !this.state.interestsExpanded,
              })
            }
            expanded={this.state.interestsExpanded}
            title={interestsTitle}
          >
            <>
              <GridHeader weightClasses={weightClasses} />
              {interests.map((i) => {
                return (
                  <GridRow
                    key={i.id}
                    orderGroup={i}
                    weightClasses={weightClasses}
                    activeRfq={activeRfq}
                    interest
                    addTradeForm={this.addTradeForm}
                    addResponseForm={this.addResponseForm}
                    addAmendForm={this.addAmendForm}
                  />
                );
              })}
            </>
          </CollapseContainer>
        </div>
      </div>
    );
  }
}

Active.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    rfqs: getAll(state), // state.orders.rfqs,
    orders: getOrders(state), // state.orders.allOrders,
    organisations: getOrganisations(state), // state.users.organisations,
    contacts: getOrganisations(state), // state.users.organisations,
    user: getActiveUser(state), // state.users,
    organisationsObject: getOrganisationsObject(state),
    usersObject: getUsersObject(state),
    trades: tradesModule.selectors.getBlotterMyTrades(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrders: (payload) => {
      dispatch(ordersModule.actions.updateOrders(ordersModule.constants.UPDATE_ORDERS, payload));
    },
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    endRFQ: (payload) => {
      dispatch(updateRFQs(UPDATE_RFQS, payload));
    },
    onTradeClick: (payload) => {
      dispatch(
        tradesModule.actions.tradeDetailsOpen(tradesModule.constants.TRADE_DETAILS_OPEN, payload),
      );
    },
  };
};

const debouncedActive = debounceRender(withStyles(styles)(Active), 200, { leading: false });

export default connect(mapStateToProps, mapDispatchToProps)(debouncedActive);
