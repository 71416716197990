const data = {
  nasdaq: {
    name: 'nasdaq',
    displayName: 'NASDAQ Sales Prices',
    searchName: 'nasdaq',
    yAxis: ['3-4KG', '4-5KG', '5-6KG'],
    xAxis: 'Week',
    yAxisUnit: 'NOK/KG',
  },
  lfex: {
    name: 'lfex',
    displayName: 'LFEX Indicative Exporter Prices (Daily)',
    searchName: 'lfexIndex',
    yAxis: ['3-4KG', '4-5KG', '5-6KG', 'Index'],
    xAxis: 'Date',
    yAxisUnit: 'NOK/KG',
  },
  lfex_4w: {
    name: 'lfex_4w',
    displayName: 'LFEX Indicative Exporter Prices (4 Week)',
    searchName: 'lfexIndex_4w',
    yAxis: ['3-4KG', '4-5KG', '5-6KG', 'Index'],
    xAxis: 'Date',
    yAxisUnit: 'NOK/KG',
  },
  fishpool: {
    name: 'fishpool',
    displayName: 'Fish Pool Index / SSB',
    searchName: 'fishpool',
    yAxis: ['Fish Pool NOK', 'SSB Oslo'],
    xAxis: 'Week',
    yAxisUnit: 'NOK/KG',
  },
  fishpool_forwards: {
    name: 'fishpool_forwards',
    displayName: 'Fish Pool Forward Price',
    searchName: 'fishpool_forwards',
    yAxis: ['NOK'],
    xAxis: 'Month',
    yAxisUnit: 'NOK/KG',
  },
  fresh_frozen: {
    name: 'fresh_frozen',
    displayName: 'Norway Salmon Fresh and Frozen Harvest (Weekly)',
    searchName: 'fresh_frozen',
    yAxis: ['Fresh', 'Frozen'],
    xAxis: 'Week',
    yAxisUnit: 'tonnes',
  },
  eurnok: {
    name: 'eurnok',
    displayName: 'EUR/NOK FX Rate (Daily)',
    searchName: 'eurnok',
    yAxis: ['EUR/NOK'],
    xAxis: 'Date',
    yAxisUnit: '',
  },
  eurnok_4w: {
    name: 'eurnok_4w',
    displayName: 'EUR/NOK FX Rate (4 Week)',
    searchName: 'eurnok_4w',
    yAxis: ['EUR/NOK'],
    xAxis: 'Date',
    yAxisUnit: '',
  },
  gbpnok: {
    name: 'gbpnok',
    displayName: 'GBP/NOK FX Rate (Daily)',
    searchName: 'gbpnok',
    yAxis: ['GBP/NOK'],
    xAxis: 'Date',
    yAxisUnit: '',
  },
};

export default data;
